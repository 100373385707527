// ############ global

export const DEVELOPMENT = "development";
export const STAGING = "staging";
export const PRODUCTION = "production";
export const SESSION_FEATURES_KEY = "features";

// ###### form search keys

export const KEYWORDS = "keywords";
export const COUNT = "count";
export const SINCE = "since";
export const UNTIL = "until";
export const COUNTRY_CODE = "countryCode";
export const RADIUS = "radius";
export const POPULARITY = "popularity";
export const EDGES = "edges";

export const MAX_TWITTER_DATE_DISTANCE_IN_DAYS = 30;
export const MAX_TWITTER_DATE_DISTANCE_IN_DAYS_V2 = 7;

// ## UI keys, part of the URL but not search params itself
// need to persist internal state
// marked with "_"
// export const PLACES_NAME = "_placesName"; // name of location selected
export const INTERNAL_COUNTRY_NAME = "_countryName"; // name of Country selected
export const INTERNAL_RANGE = "_range"; // controls knobs position

// # form reducer actions
export const ADD_KEYWORDS = "ADD_KEYWORDS";
export const UPDATE_KEYWORD = "UPDATE_KEYWORD";
export const REMOVE_KEYWORD = "REMOVE_KEYWORD";
export const ADD_COUNT = "ADD_COUNT";
export const RESET_COUNTER = "RESET_COUNTER";
export const ADD_DATE = "ADD_DATE";
export const ADD_POPULARITY = "ADD_POPULARITY";
export const INIT_FORM = "INIT_FORM";
export const RESTART_FORM = "RESTART_FORM";
export const ADD_COUNTRY = "ADD_COUNTRY";
export const REMOVE_COUNTRY = "REMOVE_COUNTRY";
export const SET_TYPE_EDGE = "SET_TYPE_EDGE";
export const RESTART_TYPE_EDGE = "RESTART_TYPE_EDGE";

// NODE selection types

export const ALL_RESULTS = "allResults";

export const edgesInitialState = [
  "mention",
  "reply",
  "quote",
  "retweet",
];

// ###### Custom Bottom Navigation

export const INSIGHTS = 0;
export const PERSPECTIVE = 1;
export const INFLUENCERS = 2;

// ######### Styling

export const DRAWER_WIDTH = "100%";
export const HEADER_HEIGHT = "120px";
export const HEADER_HEIGHT_MOBILE = "80px";

// ######### worker status messages (not used for now)

export const STOPPED = "STOPPED";
export const PREPARING_BLOB = "PREPARING_BLOB";
export const BLOB_READY = "BLOB_READY";

// ######### worker operations messages (not used for now)

export const DOWNLOAD_FILE = "DOWNLOAD_FILE";
export const EXPORT_FILE = "EXPORT_FILE";

// ############# server errors mapping (from constants.py)
// TODO: Some ideas how to share constants between server / client??

export const ERROR_CREATING_SHEET = "ERROR_CREATING_SHEET";
export const ERROR_FORMATING_SHEET = "ERROR_FORMATING_SHEET";
export const TOKEN_NOT_EXISTS = "TOKEN_NOT_EXISTS";
export const TOKEN_EXPIRED = "TOKEN_EXPIRED";
export const USER_DOES_NOT_EXIST = "USER_DOES_NOT_EXIST";
export const SESSION_DOES_NOT_EXIST = "SESSION_DOES_NOT_EXIST";
export const ERROR_USER_DELETE = "ERROR_USER_DELETE";
export const USER_LEVELS_MAX_TWEETS_PER_SEARCH =
  "USER_LEVELS_MAX_TWEETS_PER_SEARCH";
export const USER_LEVELS_MAX_SEARCHES = "USER_LEVELS_MAX_SEARCHES";

// Apollo QS Errors

export const QUERY_ERROR = "QUERY_ERROR";
export const NETWORK_ERROR = "NETWORK_ERROR";
