import React, { FC } from "react";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { useMutation } from "@apollo/client/react";
import { makeStyles } from "@material-ui/core/styles";

import SHIFT_APOLLO_ERROR from "../../apollo/mutation/shiftApolloError.local.graphql";
import { QUERY_ERROR } from "../../constants";

import { Message } from "./message";
import { useGraphQLErrors } from "../../apollo/query/useGraphQLErrors.local";

const useStyles = makeStyles({
  box: {
    maxWidth: 320,
  },
});

export const ErrorToast: FC = () => {
  const css = useStyles();
  const [shiftApolloError] = useMutation(SHIFT_APOLLO_ERROR);
  const { data } = useGraphQLErrors();
  const [error] = data?.apolloErrors || [];
  const handleClose = (): void => {
    shiftApolloError();
  };
  return (
    <Snackbar
      action={
        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={handleClose}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      }
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      className={css.box}
      message={error && <Message error={error} />}
      open={error?.name === QUERY_ERROR}
      onClose={handleClose}
    />
  );
};
