import Bugsnag from "@bugsnag/js";
import { GraphQLError } from "graphql";
import { FC, useEffect } from "react";

import { useFeatures } from "../../apollo/query/useFeatures";
import { useTranslations } from "../../apollo/query/useTranslations";

const trustedCodes = ["UNKNOWN_TWITTER_HANDLE"];

export const Message: FC<{ error: GraphQLError }> = ({ error }) => {
  const [{ FEATURE_USE_PRODUCTION_KEYS }] = useFeatures();
  const [{ somethingWrongHappened }] = useTranslations();
  useEffect(() => {
    Bugsnag.notify(error);
  });
  const errorCode = String(error.extensions?.code);
  return (FEATURE_USE_PRODUCTION_KEYS &&
  !trustedCodes.includes(errorCode)
    ? somethingWrongHappened
    : error.message) as unknown as JSX.Element;
};
