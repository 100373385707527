import React, { FC, forwardRef } from "react";
import { styled } from "@material-ui/core/styles";
import CoreButton from "@material-ui/core/Button";
import {
  palette,
  spacing,
  compose,
  style,
} from "@material-ui/system";

const variant = style({
  prop: "alternative",
  cssProperty: false,
  themeKey: "buttons",
});

const StyledComponent = styled(CoreButton)(
  compose(spacing, palette, variant),
);

type Props = {
  alternative?: string;
} & Parameters<typeof CoreButton>[0];

const Button: FC<Props> = forwardRef(
  ({ children, ...props }, ref) => (
    <StyledComponent {...props} ref={ref}>
      {children}
    </StyledComponent>
  ),
);

export default Button;
