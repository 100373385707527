/* eslint-disable react/jsx-props-no-spreading */
import React, { FC, useEffect } from "react";
import { Route, useLocation, RouteProps } from "react-router-dom";
import {
  USER_TOKEN_KEY,
  getValidToken,
  LAST_FORBIDDEN_URL_KEY,
  saveInLocalStorage,
} from "./utils/local-storage";
import { getSignInPagePath } from "./paths";

export const PrivateRoute: FC<RouteProps> = (props) => {
  const location = useLocation();
  const [allowRouting, setAllowRouting] = React.useState(false);
  useEffect(() => {
    const token = getValidToken(USER_TOKEN_KEY);
    // eslint-disable-next-line react/destructuring-assignment
    if (!token && location.pathname === props.path) {
      saveInLocalStorage(
        LAST_FORBIDDEN_URL_KEY,
        `${location.pathname}${location.search}`,
      );
      window.location.href = getSignInPagePath();
    } else {
      setAllowRouting(true);
    }
  }, []);
  return allowRouting ? <Route {...props} exact /> : null;
};
