import React, { FC } from "react";
import { makeStyles } from "@material-ui/core/styles";

import { paraqeet } from "./theme";

type Theme = typeof paraqeet;

export const useStyles = makeStyles((theme: Theme) => ({
  box: {
    color: theme.palette.primary[60],
    display: "inline-block",
    // transform: "translateY(-32%)",
    "& > svg": {
      height: "0.7em",
      width: "0.7em",
    },
  },
}));

type Props = {
  // eslint-disable-next-line react/require-default-props
  visible?: boolean;
};

export const VerifiedIcon: FC<Props> = ({ visible }) => {
  const css = useStyles();
  if (!visible) return null;
  return (
    <span className={css.box}>
      <svg
        width="80"
        height="80"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 80 80"
      >
        <g fillRule="nonzero" fill="none">
          <path
            d="M80 41.875c0 2.797-.672 5.39-2.016 7.766-1.343 2.375-3.14 4.234-5.406 5.53.063.423.094 1.079.094 1.97 0 4.234-1.422 7.828-4.234 10.797-2.829 2.984-6.235 4.468-10.22 4.468a13.38 13.38 0 01-5.093-.984c-1.25 2.562-3.047 4.625-5.406 6.203C45.375 79.219 42.797 80 40 80c-2.86 0-5.453-.766-7.766-2.328-2.328-1.547-4.109-3.625-5.359-6.25-1.61.656-3.297.984-5.094.984-3.984 0-7.406-1.484-10.265-4.469-2.86-2.968-4.282-6.578-4.282-10.796 0-.47.063-1.125.172-1.97C5.141 53.86 3.344 52.017 2 49.642c-1.328-2.375-2-4.97-2-7.766 0-2.969.75-5.703 2.234-8.172C3.72 31.234 5.72 29.406 8.22 28.22c-.656-1.782-.985-3.578-.985-5.36 0-4.218 1.422-7.828 4.282-10.797 2.859-2.968 6.28-4.468 10.265-4.468 1.782 0 3.485.328 5.094.984 1.25-2.562 3.047-4.625 5.406-6.203C34.625.797 37.203 0 40 0c2.797 0 5.375.797 7.719 2.36a15.615 15.615 0 015.406 6.203 13.322 13.322 0 015.094-.985c3.984 0 7.39 1.484 10.218 4.469 2.829 2.984 4.235 6.578 4.235 10.797 0 1.968-.297 3.75-.89 5.36 2.5 1.187 4.5 3.015 5.984 5.483C79.25 36.172 80 38.907 80 41.876z"
            fill="#1DA1F2"
          />
          <path
            d="M38.297 53.922l16.516-24.734c.421-.657.546-1.375.406-2.141-.157-.766-.547-1.375-1.203-1.781-.657-.422-1.375-.563-2.141-.453A2.74 2.74 0 0050 25.968L35.453 47.844l-6.703-6.688c-.594-.593-1.281-.875-2.047-.843-.781.03-1.453.312-2.047.843-.531.532-.797 1.203-.797 2.016 0 .797.266 1.469.797 2.016l9.203 9.203.453.359c.532.36 1.079.531 1.61.531 1.047-.015 1.844-.453 2.375-1.36z"
            fill="#FFF"
          />
        </g>
      </svg>
    </span>
  );
};
