/* eslint-disable @typescript-eslint/no-explicit-any */
import { makeVar } from "@apollo/client";
import {
  getFromLocalStorage,
  saveInLocalStorage,
} from "../../utils/local-storage";

const token = "language";
const savedLanguage = getFromLocalStorage(token) || "";

const languageVar = makeVar<string>(savedLanguage);

export const getLocale = (defaultLanguage?: string): string =>
  languageVar() || defaultLanguage || "en";

export const setLocale = (
  _ctx: any,
  { code }: { code: string },
): string => {
  saveInLocalStorage(token, code);
  return languageVar(code);
};
