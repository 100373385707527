import React, { FC } from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  box: {
    bottom: 16,
    position: "fixed",
    zIndex: 100,
    [theme.breakpoints.down("xs")]: {
      bottom: 0,
      left: 0,
      right: 0,
    },
    [theme.breakpoints.up("sm")]: {
      left: "50%",
      transform: "translateX(-50%)",
    },
  },
}));

export const FixedFooter: FC = ({ children }) => {
  const css = useStyles();
  return <footer className={css.box}>{children}</footer>;
};
