import React, { FC, SVGProps } from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  svg: {
    display: "inline-block",
    fill: "current-color",
  },
}));

export type Props = SVGProps<SVGSVGElement>;

const Svg: FC<Props> = ({ children, ...rest }) => {
  const classes = useStyles();
  return (
    <svg {...rest} className={classes.svg}>
      {children}
    </svg>
  );
};

export default Svg;
