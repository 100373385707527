import {
  makeVar,
  ServerError,
  ServerParseError,
} from "@apollo/client";
import { GraphQLError } from "graphql";

export type ApiError =
  | GraphQLError
  | Error
  | ServerError
  | ServerParseError;

export type EnhancedGraphQLError = {
  id: number;
  name: string;
} & ApiError;

export const apolloErrorsVar = makeVar<EnhancedGraphQLError[]>([]);

export const purgeApolloErrors = (): EnhancedGraphQLError[] =>
  apolloErrorsVar([]);

export const pushApolloError = (
  graphQLError: GraphQLError,
): EnhancedGraphQLError[] =>
  apolloErrorsVar([
    ...apolloErrorsVar(),
    {
      ...graphQLError,
      id: Date.now(),
    },
  ]);

export const shiftApolloError = (): EnhancedGraphQLError[] => {
  const [, ...prevErors] = apolloErrorsVar();
  return apolloErrorsVar(prevErors);
};
