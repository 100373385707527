import { ThemeOptions } from "@material-ui/core";
import { createMuiTheme } from "@material-ui/core/styles";

const options: ThemeOptions = {
  overrides: {
    MuiTypography: {
      colorPrimary: {
        color: "#443e36",
      },
      gutterBottom: {
        marginBottom: 8,
      },
    },
  },
  palette: {
    primary: {
      light: "#FFFED2",
      main: "#FFFED2",
      dark: "#F15A29",
    },
    secondary: {
      main: "#FFBE57",
    },
    background: {
      default: "#c6d8e0",
    },
    paraqeet: {
      red: "#F15A29",
      orange: "#FFBE57",
      blue: "#03F4FF",
      brown: "#897D6D",
      green: "#8dc63f",
    },
    // using https://pinetools.com/darken-color fron paraqeet base colors
    links: {
      reply: "#842608",
      retweet: "#ab6800",
      mention: "#443e36",
      quote: "#007b81",
    },
  },
  typography: {
    fontFamily: [
      "ArchivoNarrow Medium",
      "NotoSerif Italic",
      "NotoSerif Regular",
      "SourceSansPro Regular",
      "SourceSansPro SemiBold",
      "Helvetica Neue",
      "Arial",
      "sans-serif",
    ].join(" "),
  },
} as ThemeOptions;

const theme = createMuiTheme(options);
// console.log(theme);
// eslint-disable-next-line import/prefer-default-export
export { theme };
