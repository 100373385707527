// eslint-disable-next-line no-unused-vars

function isObject(x) {
  return typeof x === "object" && x !== null;
}

function toObject(params) {
  if (Object.entries(params).length === 0) return params;
  const search = params.substring(1);
  if (!search) return {};
  return JSON.parse(
    `{"${decodeURIComponent(search)
      .replace(/"/g, '\\"') // eslint-disable-line quotes
      .replace(/&/g, '","') // eslint-disable-line quotes
      .replace(/=/g, '":"')}"}`, // eslint-disable-line quotes
  );
}

// eslint-disable-next-line no-unused-vars
function toArrayOfStrings(obj) {
  return Object.entries(obj)
    .map(([key, value]) => {
      if (value) return key;
      return undefined;
    })
    .filter((v) => !!v)
    .reduce((acc, v) => `${acc},${v}`);
}

export { toObject, isObject };
