/* eslint-disable no-underscore-dangle */
// #region imports
import {
  ApolloClient,
  defaultDataIdFromObject,
  from,
  HttpLink,
  InMemoryCache,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { setContext } from "@apollo/client/link/context";
import Bugsnag from "@bugsnag/js";

import fetch from "isomorphic-unfetch";

import {
  USER_TOKEN_KEY,
  getFromLocalStorage,
  removeLocalStorageKey,
} from "../utils/local-storage";
import {
  NETWORK_ERROR,
  QUERY_ERROR,
  SESSION_DOES_NOT_EXIST,
  TOKEN_EXPIRED,
  TOKEN_NOT_EXISTS,
} from "../constants";
import { typeDefs, resolvers } from "./store";
import {
  EnhancedGraphQLError,
  apolloErrorsVar,
  getLocale,
  ApiError,
} from "./var";

const httpLink = new HttpLink({
  credentials: "include",
  uri: process.env.GRAPHQL_ENDPOINT,
  useGETForQueries: false,
  fetch,
});
// #endregion

const authLink = setContext((request, previousContext) => {
  const token = getFromLocalStorage(USER_TOKEN_KEY);
  if (!token) return previousContext;
  return {
    ...previousContext,
    headers: {
      ...previousContext.headers,
      Authorization: `Bearer ${token}`,
    },
  };
});

const languageLink = setContext((request, previousContext) => ({
  ...previousContext,
  headers: {
    ...previousContext.headers,
    "Accept-Language": getLocale(),
  },
}));

const cache = new InMemoryCache({
  dataIdFromObject(responseObject): string | undefined {
    switch (responseObject.__typename) {
      case "DataNode": {
        return `DataNode:${responseObject.id}-${responseObject.createdAt}`;
      }
      case "DataLink": {
        return `DataLink:${responseObject.source}-${responseObject.target}`;
      }
      default:
        return defaultDataIdFromObject(responseObject);
    }
  },
  typePolicies: {
    Query: {
      fields: {
        apolloErrors: {
          read(): EnhancedGraphQLError[] {
            return apolloErrorsVar();
          },
        },
      },
    },
    User: {
      fields: {
        locale: {
          merge(existing, incoming): string {
            return getLocale(incoming);
          },
          read(): string {
            return getLocale();
          },
        },
      },
    },
  },
});

const notifyApollo = (error: ApiError, name: string): void => {
  resolvers.Mutation.pushApolloError({ ...error, name });
  Bugsnag.notify(error);
};

const matches = (
  { message }: ApiError,
  ...types: string[]
): boolean => types.includes(message);

const errorsLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach((error) => {
      if (matches(error, TOKEN_EXPIRED, SESSION_DOES_NOT_EXIST)) {
        removeLocalStorageKey(USER_TOKEN_KEY);
        window.location.href = "/";
      } else if (!matches(error, TOKEN_NOT_EXISTS)) {
        notifyApollo(error, QUERY_ERROR);
      }
    });
  }
  if (networkError) notifyApollo(networkError, NETWORK_ERROR);
});

export const client = new ApolloClient({
  link: from([authLink, errorsLink, languageLink, httpLink]),
  cache,
  connectToDevTools: true,
  resolvers,
  typeDefs,
});
