/* eslint-disable react/require-default-props */
import React, { FC, useEffect, HtmlHTMLAttributes } from "react";
import {
  useInView,
  IntersectionOptions,
} from "react-intersection-observer";

type Props = {
  disabled?: boolean;
  loadMore(): void;
  options?: IntersectionOptions;
} & HtmlHTMLAttributes<HTMLDivElement>;

export const LazyLoader: FC<Props> = ({
  children,
  disabled,
  options,
  loadMore,
  ...rest
}): JSX.Element => {
  const [ref, inView] = useInView(options);
  function effect(): void {
    if (inView && !disabled) loadMore();
  }
  useEffect(effect, [inView, disabled]);
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <div {...rest} ref={ref}>
      {children}
    </div>
  );
};
