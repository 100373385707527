import {
  compose,
  palette,
  typography,
  style,
  spacing,
} from "@material-ui/system";
import { styled } from "@material-ui/core/styles";
import CoreTypography, {
  TypographyTypeMap,
} from "@material-ui/core/Typography";
import { OverridableComponent } from "@material-ui/core/OverridableComponent";
import { ElementType } from "react";

const alternative = style({
  prop: "alternative",
  cssProperty: false,
  themeKey: "typography",
});

const Typography = styled<
  OverridableComponent<TypographyTypeMap<{ component?: ElementType }>>
>(CoreTypography)(compose(palette, typography, alternative, spacing));

export default Typography;
