import CoreGrid from "@material-ui/core/Grid";
import { styled } from "@material-ui/core/styles";
import {
  spacing,
  compose,
  flexbox,
  typography,
} from "@material-ui/system";

const Grid = styled(CoreGrid)(compose(spacing, flexbox, typography));

export default Grid;
