import customTypography from "./typography";
import palette from "./palette";

const links = {
  primary: {
    ...customTypography.bodyMedium,
  },
  underline: {
    ...customTypography.bodyMedium,
    textDecoration: "underline",
    textDecorationColor: palette.secondary.main,
  },
};

export default links;
