import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import { useEffect, useState } from "react";
import {
  sessionWrite,
} from "../../utils/session-storage";
import { SESSION_FEATURES_KEY } from "../../constants";
import {
  FeatureTogglesQuery,
  FeatureTogglesQueryVariables,
} from "../../../build/types/codegen";

const query = gql`
  query FeatureToggles($active: Boolean) {
    featureToggles(active: $active) {
      id
      feature
    }
  }
`;

const variables: FeatureTogglesQueryVariables = {
  active: true,
};

export type FeaturesRecord = Record<string, boolean>;

interface UseFeatures {
  (): [FeaturesRecord, boolean];
}

export const useFeatures: UseFeatures = () => {
  const [features, setFeatures] = useState({});
  const { data, loading } = useQuery<FeatureTogglesQuery>(query, {
    variables,
  });
  const featureToggles = data?.featureToggles || [];
  const recivedFeatures = featureToggles.reduce<FeaturesRecord>(
    (acc, item) => {
      if (!item?.feature) return acc;
      return {
        ...acc,
        [item.feature]: true,
      };
    },
    {},
  );
  useEffect(() => {
    sessionWrite(SESSION_FEATURES_KEY, recivedFeatures);
    setFeatures(recivedFeatures);
  }, [loading]);
  return [features, loading];
};
