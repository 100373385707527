// https://www.christopherbiscardi.com/post/composition-of-styles-strings-vs-objects
const fonts = {
  "@global @font-face": [
    {
      fontFamily: "Poppins Medium Italic",
      src: "url('fonts/Poppins-MediumItalic.woff2') format('woff2'), url('fonts/Poppins-MediumItalic.woff') format('woff')",
      fontWeight: "500",
      fontStyle: "italic",
    },

    {
      fontFamily: "Poppins SemiBold",
      src: "url('fonts/Poppins-SemiBold.woff2') format('woff2'), url('fonts/Poppins-SemiBold.woff') format('woff')",
      fontWeight: "600",
      fontStyle: "normal",
    },
    {
      fontFamily: "Poppins Medium",
      src: "url('fonts/Poppins-Medium.woff2') format('woff2'), url('fonts/Poppins-Medium.woff') format('woff')",
      fontWeight: "500",
      fontStyle: "normal",
    },
    {
      fontFamily: "Poppins Regular Italic",
      src: "url('fonts/Poppins-Italic.woff2') format('woff2'), url('fonts/Poppins-Italic.woff') format('woff')",
      fontWeight: "normal",
      fontStyle: "italic",
    },
    {
      fontFamily: "Poppins",
      src: "url('fonts/Poppins-LightItalic.woff2') format('woff2'), url('fonts/Poppins-LightItalic.woff') format('woff')",
      fontWeight: "300",
      fontStyle: "italic",
    },
    {
      fontFamily: "Poppins Regular",
      src: "url('fonts/Poppins-Regular.woff2') format('woff2'), url('fonts/Poppins-Regular.woff') format('woff')",
      fontWeight: "normal",
      fontStyle: "normal",
    },
    {
      fontFamily: "Poppins Bold Italic",
      src: "url('fonts/Poppins-BoldItalic.woff2') format('woff2'), url('fonts/Poppins-BoldItalic.woff') format('woff')",
      fontWeight: "bold",
      fontStyle: "italic",
    },
    {
      fontFamily: "Poppins Bold",
      src: "url('fonts/Poppins-Bold.woff2') format('woff2'), url('fonts/Poppins-Bold.woff') format('woff')",
      fontWeight: "bold",
      fontStyle: "normal",
    },
    {
      fontFamily: "Poppins SemiBold Italic",
      src: "url('fonts/Poppins-SemiBoldItalic.woff2') format('woff2'), url('fonts/Poppins-SemiBoldItalic.woff') format('woff')",
      fontWeight: "600",
      fontStyle: "italic",
    },
    {
      fontFamily: "ArchivoNarrow Medium",
      src: "url(fonts/ArchivoNarrow-Medium.woff2) format('woff2');",
    },
    {
      fontFamily: "NotoSerif Italic",
      src: "url(fonts/NotoSerif-Italic.woff2) format('woff2');",
    },
    {
      fontFamily: "NotoSerif Regular",
      src: "url(fonts/NotoSerif.woff2) format('woff2');",
    },
    {
      fontFamily: "SourceSansPro Regular",
      src: "url(fonts/SourceSansPro-Regular.woff2) format('woff2');",
    },
    {
      fontFamily: "SourceSansPro SemiBold",
      fontWeight: "bold",
      src: "url(fonts/SourceSansPro-SemiBold.woff2) format('woff2');",
    },
  ],
};

export default fonts;

/* 

keep old ones for reference in the code

ArchivoNarrow Medium,
NotoSerif Italic",
NotoSerif Regular",
SourceSansPro Regular,
SourceSansPro SemiBold,
Helvetica Neue,
Arial,
sans-serif,

*/
