import { Theme } from "@material-ui/core";
import palette from "./palette";

const useInputs = (theme: Theme): Record<string, unknown> => ({
  refineForm: {
    color: theme.palette.common.white,
    backgroundColor: "rgba(255, 255, 255, 0.4)",
    borderRadius: 20,
    width: "auto",
    paddingLeft: "10px",
  },
  searchForm: {
    borderRadius: 4,
    backgroundColor: palette.primary["5"],
    marginBottom: theme.spacing(1),
    padding: theme.spacing(2),
    width: "100%",
  },
});

export default useInputs;
