/* eslint-disable react/require-default-props */
import React, { FC } from "react";
import { styled } from "@material-ui/core/styles";
import CoreLink from "@material-ui/core/Link";
import { compose, style } from "@material-ui/system";

const variant = style({
  prop: "alternative",
  cssProperty: false,
  themeKey: "links",
});

const DEFAULT_LINK = "primary";
const Variant = styled(CoreLink)(compose(variant));

const handlePreventDefault = (event: React.MouseEvent): void =>
  event.preventDefault();

type Props = {
  variant?: string;
  preventDefault?: boolean | undefined;
  children: React.ReactNode;
  href?: string;
  alternative?: string;
  onClick?(event: React.MouseEvent): void;
  target?: string;
};

const Link: FC<Props> = ({
  variant: localVariant,
  preventDefault,
  children,
  onClick,
  ...props
}): JSX.Element => (
  <Variant
    alternative={localVariant || DEFAULT_LINK}
    onClick={preventDefault ? handlePreventDefault : onClick}
    {...props}
  >
    {children}
  </Variant>
);

export default Link;
