/* eslint-disable react/jsx-props-no-spreading */
import React, { FC } from "react";
import { styled } from "@material-ui/core/styles";
import CoreAvatar from "@material-ui/core/Avatar";
import { spacing, compose, style } from "@material-ui/system";

import { paraqeet } from "./theme";

const variant = style({
  prop: "variation",
  cssProperty: false,
  themeKey: "Avatar",
});

const Variant = styled(CoreAvatar)(compose(spacing, variant));

type CoreAvatarProps = Parameters<typeof CoreAvatar>[0];

type Props = {
  // eslint-disable-next-line react/require-default-props
  variation?: keyof typeof paraqeet["Avatar"];
} & CoreAvatarProps;

export const Avatar: FC<Props> = ({
  children,
  ...props
}): JSX.Element => <Variant {...props}>{children}</Variant>;
