import { makeStyles } from "@material-ui/core/styles";

import { paraqeet } from "./paraqeet";
import fonts from "./fonts";

const useGlobalStyles = makeStyles((theme: typeof paraqeet) => ({
  "@global": {
    // fonts
    ...fonts,
    "html, body": {
      backgroundColor: theme.palette.common.white,
      // fontFamily: "SourceSansPro Regular",
      display: "flex",
      flexFlow: "column",
      margin: 0,
      padding: 0,
    },
    "#root": {
      minHeight: "100vh",
    },
    // Hack for slider styles
    ".rc-slider": {
      width: "90%",
      margin: "0 auto",
    },
    ".rc-slider-handle": {
      border: "none",
      marginTop: "-12px",
      width: "30px",
      height: "30px",
    },
    // end hack

    // network-graph styles
    ".svg-text": {
      fill: theme.palette.text,
      fontFamily: "Helvetica Neue",
      fontSize: "12px",
      fontWeight: "bold",
    },
    "text, circle": {
      "-webkit-transition":
        "opacity 0.2s ease-in-out, fill 0.2s ease-in-out",
      "-moz-transition":
        "opacity 0.2s ease-in-out, fill 0.2s ease-in-out",
      transition: "opacity 0.2s ease-in-out, fill 0.2s ease-in-out",
    },

    line: {
      "-webkit-transition":
        "opacity 0.2s ease-in-out, stroke 0.2s ease-in-out",
      "-moz-transition":
        "opacity 0.2s ease-in-out, stroke 0.2s ease-in-out",
      transition: "opacity 0.2s ease-in-out, stroke 0.2s ease-in-out",
    },
  },
}));

export default (): JSX.Element => {
  useGlobalStyles();
  return null as unknown as JSX.Element;
};
