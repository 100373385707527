export default {
  dropHover: {
    boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.15)",
    borderRadius: "4px",
  },
  selectedFormHover: {
    boxShadow: "0px 3px 10px #D6D9E3",
    borderRadius: "4px",
  },
  desktopMenu: {
    boxShadow:
      "0px 10px 15px rgba(0, 0, 0, 0.15), 0px 1px 10px rgba(104, 119, 133, 0.1)",
    borderRadius: "4px",
  },
  cardHover: {
    boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.15)",
    borderRadius: "4px",
  },
};
