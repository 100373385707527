export default {
  primary: {
    main: "#324375",
    contrastText: "#F1F3F8",
    "5": "#F1F3F8",
    "10": "#EBECF1",
    "20": "#D6D9E3",
    "40": "#ADB4C8",
    "60": "#848EAC",
    "80": "#5B6991",
    "100": "#324375",
  }, // Relatable
  secondary: {
    main: "#32DAF3",
    contrastText: "#F5FDFE",
    "5": "#F5FDFE",
    "10": "#EAFBFE",
    "20": "#D6F8FD",
    "40": "#ADF0FA",
    "60": "#84E9F8",
    "80": "#5BE1F6",
    "100": "#32DAF3",
  }, // Clarity
  tertiary: {
    main: "#7125EC",
    contrastText: "#F8F5FD",
    "5": "#F8F5FD",
    "10": "#F1E9FD",
    "20": "#E3D3FB",
    "40": "#C6A8F7",
    "60": "#AA7CF4",
    "80": "#8D51F0",
    "100": "#7125EC",
  },
  intriguing: {
    "5": "#F7FEF4",
    "10": "#EEFEE9",
    "20": "#DDFDD4",
    "40": "#BBFBA9",
    "60": "#9AF97D",
    "80": "#78F752",
    "100": "#56F527",
  },
  primaryText: "#23233D",
  secondaryText: "#3B3B52",
  deactiveText: "#687785",
  networkGraphBackground: "#F7F9FF",
  formsBackground: "#ffffff",
  white: "#ffffff",
  hyperlink: "#1B95E0",
  error: "#FF4141",
  errorBackground: "#FFF2F2",
  formFieldBackground: "#F1F3F8",
  nodes: {
    retweet: "#03F4FF",
    mention: "#00A9C0",
    quote: "#6066FF",
    reply: "#753BD2",
    source: "#ADB4C8",
  },
  strokes: {
    sandbox: "#FF4141",
    normal: "#D6D9E3",
    highlighted: "#848EAC",
  },
  roles: {
    administrator: "#F15A29",
    guest: "#03F4FF",
    researcher: "#FFBE57",
  },
};
