import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CustomLoading from "./custom-loading";

const useStyles = makeStyles(() => ({
  container: {
    height: "100vh",
    backgroundColor: "black",
    opacity: 0.2,
    position: "absolute",
    width: "100vw",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 10,
  },
}));

export const FullViewPortLoading = (): JSX.Element => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <CustomLoading />
    </div>
  );
};
