import React, { FC } from "react";
import { makeStyles } from "@material-ui/core/styles";

import { paraqeet } from "../ui/theme";

const useStyles = makeStyles((theme: typeof paraqeet) => ({
  content: {
    height: "100%",
  },
  centeredContent: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    [`${theme.breakpoints.up("md")} and (orientation: landscape)`]: {
      height: "100vh",
    },
    [`${theme.breakpoints.up(320)} and (orientation: portrait)`]: {
      height: "100vh",
    },
    width: "100%",
    position: "relative",
  },
}));

type Props = {
  centered: boolean;
};

const Layout: FC<Props> = ({ children, centered }) => {
  const classes = useStyles();
  return centered ? (
    <div className={classes.centeredContent}>{children}</div>
  ) : (
    <main className={classes.content}>{children}</main>
  );
};

export default Layout;
