import React, { FC } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { ClassNameMap } from "@material-ui/styles";

import { paraqeet } from "./theme";

type Props = {
  text: string;
};

const useStyles = makeStyles((theme: typeof paraqeet) => ({
  box: ({ text }: Props): ClassNameMap<string> => ({
    alignItems: "center",
    display: "flex",
    "&:before": {
      background: theme.palette.nodes[text],
      borderRadius: 8,
      content: '""',
      display: "block",
      height: 8,
      marginRight: 4,
      width: 8,
    } as unknown as string,
  }),
}));

export const TwitterPostType: FC<Props> = ({ text }): JSX.Element => {
  const css = useStyles({ text });
  return (
    <Typography className={`${css.box}`} variant="body2">
      {text}
    </Typography>
  );
};
