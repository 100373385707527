import decodeJWT from "jwt-decode";

type User = {
  at_hash: string;
  aud: string;
  azp: string;
  email: string;
  email_verified: boolean;
  exp: number;
  family_name: string;
  given_name: string;
  iat: number;
  iss: string;
  locale: string;
  name: string;
  picture: string;
};

export const USER_TOKEN_KEY = "userToken";
export const USER_COUNTRY_TOKEN_KEY = "userCountryToken";
export const LAST_FORBIDDEN_URL_KEY = "lastForbiddenUrl";
const ISSUER = "https://accounts.google.com";

export const getUserCountryToken = (email: string): string =>
  `${USER_COUNTRY_TOKEN_KEY}-${email}`;

// export function extractToken(response): string {
//   return response.xhr
//     .getResponseHeader("Authorization")
//     .split(" ")[1];
// }

export function removeLocalStorageKey(key: string): void {
  localStorage.removeItem(key);
}

export function saveInLocalStorage(token: string, key: string): void {
  if (token) localStorage.setItem(token, key);
}

export function saveInLocalStorageAsJson<P>(
  token: string,
  payload: P,
): void {
  if (token) localStorage.setItem(token, JSON.stringify(payload));
}

export function getFromLocalStorage(key: string): string | null {
  return localStorage.getItem(key);
}

export function getFromLocalStorageAsJson<R>(
  key: string,
  fallback: R,
): R {
  try {
    const string = getFromLocalStorage(key);
    return JSON.parse(string as string) as R;
  } catch (error) {
    return fallback;
  }
}

export function getValidToken(key: string): string | null {
  const token = localStorage.getItem(key);
  if (!token) return null;
  try {
    const decodedToken = decodeJWT<User>(token);
    const now = Date.now() / 1000;
    if (now > decodedToken.exp || ISSUER !== decodedToken.iss) {
      return null;
    }
    return token;
  } catch (error) {
    return null;
  }
}

export function getDecodedToken(key: string): User | null {
  const validToken = getValidToken(key);
  if (validToken) return decodeJWT<User>(validToken);
  return null;
}

export const getUser = (key: string): User => {
  const user = getDecodedToken(key);
  if (!user) throw new Error("Not authorized");
  return user;
};
