type Subscriber<M> = (m: M) => void;
type Unsubscribe = () => void;

export class PubSub<M> {
  private s: Subscriber<M>[] = [];

  publish = (m: M): void => this.s.forEach((s) => s(m));

  subscribe = (s: Subscriber<M>): Unsubscribe => {
    this.s.push(s);
    return (): void => {
      this.s = this.s.filter((c) => c !== s);
    };
  };
}
