import React, { FC } from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  box: {
    left: 0,
    right: 0,
    top: 0,
    position: "fixed",
    zIndex: 200,
  },
});

export const FixedHeader: FC = ({ children }) => {
  const css = useStyles();
  return <div className={css.box}>{children}</div>;
};
