import { useEffect, useState } from "react";

import { PubSub } from "../../utils/pub-sub";

type State = {
  isOpen: boolean;
  reason?: string;
};

type UseRestrictedDialog = {
  closeRestrictedDialog(): void;
  openRestrictedDialog(reason: string | null | undefined): void;
} & State;

const pubSub = new PubSub<State>();

export const useRestrictedDialog = (): UseRestrictedDialog => {
  const [state, setState] = useState<State>({ isOpen: false });

  useEffect(() => pubSub.subscribe(setState), []);

  function openRestrictedDialog(reason: string): void {
    pubSub.publish({ isOpen: true, reason });
  }

  function closeRestrictedDialog(): void {
    pubSub.publish({ isOpen: false });
  }

  return { ...state, closeRestrictedDialog, openRestrictedDialog };
};
