import React, { useCallback } from "react";
import NotificationsActiveOutlinedIcon from "@material-ui/icons/NotificationsActiveOutlined";
import { makeStyles } from "@material-ui/core/styles";
import { GridItemsAlignment } from "@material-ui/core";

import { Typography, Icon, Button, Grid } from "../../ui";
import DialogWrapper from "../dialog-wrapper";
import { useTranslations } from "../../apollo/query/useTranslations";
import { useRestrictedDialog } from "./use-restricted-dialog";

const useStyles = makeStyles(({ breakpoints }) => ({
  nav: {
    display: "flex",
    gap: "16px",
    flexDirection: "column-reverse",
    marginTop: "16px",
    [breakpoints.up("sm")]: {
      alignItems: "center",
      flexDirection: "row",
      justifyContent: "flex-end",
      width: "100%",
    },
  },
  okButton: {
    padding: "16.5px 25.55px",
  },
}));

const RestrictedDialog = (): JSX.Element => {
  const css = useStyles();
  const [
    { accountIsLimited, exploreUnlimited, okay, upgradeMessage },
  ] = useTranslations();
  const { isOpen, closeRestrictedDialog, reason } =
    useRestrictedDialog();

  const callToAction = useCallback((): void => {
    window.open(process.env.UPSELL_MARKETING_FORM, "_self");
  }, []);

  return (
    <DialogWrapper isOpen={isOpen} noScroll>
      <Grid
        container
        spacing={3}
        p={2}
        alignItems={"center" as GridItemsAlignment}
        direction="column"
      >
        <Icon>
          <NotificationsActiveOutlinedIcon />
        </Icon>
        <Typography alternative="titleBigger" mt={2}>
          {accountIsLimited}
        </Typography>
        <p>
          <b>{reason}</b>
        </p>
        <p>{upgradeMessage}</p>
        <div className={css.nav}>
          <Button
            className={css.okButton}
            onClick={closeRestrictedDialog}
          >
            {okay}
          </Button>
          <Button alternative="primary" onClick={callToAction}>
            {exploreUnlimited}
          </Button>
        </div>
      </Grid>
    </DialogWrapper>
  );
};

export default RestrictedDialog;
