/* eslint-disable import/no-absolute-path */
import React, { lazy } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
} from "react-router-dom";

import {
  getSignInPagePath,
  getSearchFormPagePath,
  getStyleGuidePath,
  getInsightsPagePath,
  getPerspectivePagePath,
  getInfluencersPagePath,
  getRequestAccessPath,
  getMySearchesPath,
} from "./paths";
import { PrivateRoute } from "./private-route";
import SignInPage from "./pages/sign-in-page";
import { FullViewPortLoading } from "./components/loading/fullviewport-loading";
import { useFeatures } from "./apollo/query/useFeatures";
import { ScrollRestoration } from "./components/scroll-restoration/scroll-restoration";
import RestrictedDialog from "./components/restricted-dialog/restricted-dialog";
import { useTranslations } from "./apollo/query/useTranslations";

const Page404 = lazy(() => import("./pages/404"));
const SearchFormPage = lazy(() => import("./pages/search-form-page"));
const PerspectivePage = lazy(
  () => import("./pages/perspective-page"),
);
const InsightsPage = lazy(() => import("./pages/insights-page"));
const InfluencersPage = lazy(
  () => import("./pages/influencers-page"),
);
const StyleGuide = lazy(() => import("./pages/style-guide"));
const RequestAccess = lazy(
  () => import("./pages/request-access-page"),
);
const MySearches = lazy(() => import("./pages/my-searches"));

export default (): JSX.Element => {
  const [, loading] = useTranslations();
  const [{ FEATURE_SAVED_SEARCHES }, featuresIsLoading] =
    useFeatures();
  if (loading || featuresIsLoading) return <FullViewPortLoading />;
  return (
    <Router>
      <ScrollRestoration />
      <Switch>
        <Route
          path={getSignInPagePath()}
          exact
          component={SignInPage}
        />
        <PrivateRoute
          exact
          path={getSearchFormPagePath()}
          component={SearchFormPage}
        />
        <PrivateRoute
          exact
          path={getInsightsPagePath()}
          component={InsightsPage}
        />
        <PrivateRoute
          exact
          path={getPerspectivePagePath()}
          component={PerspectivePage}
        />
        <PrivateRoute
          exact
          path={getInfluencersPagePath()}
          component={InfluencersPage}
        />
        <Route
          exact
          path={getStyleGuidePath()}
          component={StyleGuide}
        />
        <Route
          exact
          path={getRequestAccessPath()}
          component={RequestAccess}
        />
        {FEATURE_SAVED_SEARCHES && (
          <PrivateRoute
            exact
            path={getMySearchesPath()}
            component={MySearches}
          />
        )}
        <Route path="*" component={Page404} />
      </Switch>
      <RestrictedDialog />
    </Router>
  );
};
