import React, { FC } from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  box: {
    position: "sticky",
    top: 0,
    zIndex: 200,
  },
});

export const StickyHeader: FC = ({ children }) => {
  const css = useStyles();
  return <div className={css.box}>{children}</div>;
};
