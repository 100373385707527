import gql from "graphql-tag";
import { QueryResult, useQuery } from "@apollo/client/react";
import { GraphQLError } from "graphql";

export type Errors = {
  apolloErrors: GraphQLError[];
};

const query = gql`
  query ApolloErrors {
    apolloErrors @client {
      id
      message
      name
    }
  }
`;

export const useGraphQLErrors = (): QueryResult<Errors> =>
  useQuery(query);
