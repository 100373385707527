/* eslint-disable react/require-default-props */
import React, { FC, HTMLProps } from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";

const useStyles = makeStyles({
  box: {
    display: "block",
    overflow: "hidden",
    overflowScrolling: "touch",
    scrollBehavior: "smooth",
  },
  hideScrollbars: {
    scrollbarWidth: "none",
    overflowStyle: "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  horizontal: {
    overflowX: "auto",
  },
  vertical: {
    overflowY: "auto",
  },
});

type Props = {
  hideScrollbars?: boolean;
  horizontal?: boolean;
  vertical?: boolean;
} & HTMLProps<HTMLDivElement>;

export const ScrolBox: FC<Props> = ({
  children,
  hideScrollbars,
  horizontal,
  vertical,
  ...rest
}) => {
  const css = useStyles();
  const classes = clsx(
    css.box,
    hideScrollbars && css.hideScrollbars,
    horizontal && css.horizontal,
    vertical && css.vertical,
  );
  return (
    <div {...rest} className={classes}>
      {children}
    </div>
  );
};
