import Select from "@material-ui/core/NativeSelect";
import { withStyles } from "@material-ui/core/styles";

export const NativeSelect = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary["5"],
    borderRadius: 4,
    height: 64,
    padding: `0 ${theme.spacing(2)}px`,
  },
}))(Select);
