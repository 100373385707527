import React from "react";
import { ApolloProvider } from "@apollo/client/react";
import { MuiThemeProvider } from "@material-ui/core/styles";

import { theme } from "./ui/theme";
import { ErrorBoundary } from "./components/error-boundary";
import { ErrorToast } from "./components/error-toast";
import { Sandbox } from "./components/sandbox";
import { FullViewPortLoading } from "./components/loading/fullviewport-loading";
import { client } from "./apollo";
import Routes from "./routes";
import GlobalStyles from "./ui/theme/global-styles";
import { ThemeProvider } from "./ui";

/* if (process.env.NODE_ENV !== "production") {
  const whyDidYouRender = require("@welldone-software/why-did-you-render");
  whyDidYouRender(React);
}
 */

const App = (): JSX.Element => (
  <ApolloProvider client={client}>
    <MuiThemeProvider theme={theme}>
      <GlobalStyles />
      <ErrorBoundary>
        <React.Suspense fallback={<FullViewPortLoading />}>
          <ThemeProvider>
            <Routes />
          </ThemeProvider>
          <ErrorToast />
          <Sandbox />
        </React.Suspense>
      </ErrorBoundary>
    </MuiThemeProvider>
  </ApolloProvider>
);

export default App;
