import Box from "@material-ui/core/Box";
import { styled } from "@material-ui/core/styles";
import {
  flexbox,
  borders,
  compose,
  palette,
  sizing,
  shadows,
  spacing,
} from "@material-ui/system";

const defaultCss = (): Record<string, unknown> => ({
  alignItems: "center",
  display: "flex",
  justifyContent: "space-between",
});

const system = compose(
  defaultCss,
  borders,
  flexbox,
  palette,
  sizing,
  spacing,
  shadows,
);

export const FlexBox = styled(Box)(system);
