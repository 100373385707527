import React, { FC } from "react";
import { MuiThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";

import { paraqeet } from "./theme";

export const ThemeProvider: FC = ({ children }) => (
  <MuiThemeProvider theme={paraqeet}>
    <CssBaseline />
    {children}
  </MuiThemeProvider>
);
