/* eslint-disable react/require-default-props */
import React, { FC, ReactNode } from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

import { LogoVertical } from "../../svg";
import { ThemeProvider } from "../../ui";
import { useTranslations } from "../../apollo/query/useTranslations";

const useStyles = makeStyles((theme) => ({
  box: {
    paddingTop: theme.spacing(8),
    textAlign: "center",
  },
  message: {
    maxWidth: "50vw",
    margin: "auto",
    minWidth: 300,
  },
  section: {
    paddingBottom: theme.spacing(6),
    paddingTop: theme.spacing(6),
  },
}));

type Props = {
  backLink?: string;
  message: ReactNode;
  title: ReactNode;
};

export const ErrorPage: FC<Props> = ({
  backLink,
  message,
  title,
}) => {
  const css = useStyles();
  const [{ backToHomepage }] = useTranslations();
  return (
    <ThemeProvider>
      <div className={css.box}>
        {backLink ? (
          <a href="/">
            <LogoVertical />
          </a>
        ) : (
          <LogoVertical />
        )}
        <section className={css.section}>
          <Typography component="h1" variant="h1">
            {title}
          </Typography>
          <Typography className={css.message} variant="body1">
            {message}
          </Typography>
        </section>
        {backLink && (
          <Link to={backLink}>
            <Button variant="contained" color="primary" size="large">
              {backToHomepage}
            </Button>
          </Link>
        )}
      </div>
    </ThemeProvider>
  );
};
