import { Theme } from "@material-ui/core";

const useChips = (theme: Theme): Record<string, unknown> => ({
  refineForm: {
    color: theme.palette.common.white,
    backgroundColor: "rgba(255, 255, 255, 0.4)",
    border: 0,
    "& svg": {
      color: theme.palette.common.white,
      "&:hover": {
        color: "rgba(255, 255, 255, 0.5)",
      },
    },
  },
});

export default useChips;
