import { styled } from "@material-ui/core/styles";
import CoreChip from "@material-ui/core/Chip";
import { spacing, compose, style } from "@material-ui/system";

const variant = style({
  prop: "alternative",
  cssProperty: false,
  themeKey: "chips",
});

const Variant = styled(CoreChip)(compose(spacing, variant));

export default Variant;
