import { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";

function useScrollRestoration(): void {
  const history = useHistory();
  const location = useLocation();
  useEffect(
    () =>
      history.listen(({ pathname: p }, action) => {
        if (action === "PUSH" && p !== location.pathname)
          window.scrollTo(0, 0);
      }),
    [location.pathname],
  );
}

export function ScrollRestoration(): JSX.Element {
  useScrollRestoration();
  return null as unknown as JSX.Element;
}
