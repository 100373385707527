import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";

import { useFeatures } from "../../apollo/query/useFeatures";

const useStyles = makeStyles(({ breakpoints, palette }: Theme) => ({
  sandbox: {
    border: `4px dashed ${palette.error.main}`,
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    pointerEvents: "none",
    zIndex: 99999,
    [breakpoints.up("sm")]: {
      borderWidth: 8,
    },
  },
}));

export const Sandbox = (): JSX.Element => {
  const css = useStyles();
  const [{ FEATURE_USE_PRODUCTION_KEYS = false }, isLoading] =
    useFeatures();

  return (!FEATURE_USE_PRODUCTION_KEYS && !isLoading && (
    <div className={css.sandbox} />
  )) as JSX.Element;
};
