/* eslint-disable react/require-default-props */
import React, { FC } from "react";
import { styled } from "@material-ui/core/styles";
import InputBase from "@material-ui/core/InputBase";
import InputAdornment from "@material-ui/core/InputAdornment";
import {
  palette,
  spacing,
  compose,
  style,
} from "@material-ui/system";

const variant = style({
  prop: "alternative",
  cssProperty: false,
  themeKey: "inputs",
});

const Variant = styled(InputBase)(compose(spacing, palette, variant));

type Props = {
  alternative?: string;
  Adornment?: () => JSX.Element;
} & Parameters<typeof InputBase>[0];

const Input: FC<Props> = ({ alternative, Adornment, ...rest }) => {
  const adornmentAttr = Adornment ? (
    <InputAdornment position="end">
      <Adornment />
    </InputAdornment>
  ) : null;

  return (
    <Variant
      alternative={alternative}
      endAdornment={adornmentAttr}
      {...rest}
    />
  );
};
export default Input;
