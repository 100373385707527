/* eslint-disable react/require-default-props */
import React, { FC, ReactNode } from "react";
import Dialog from "@material-ui/core/Dialog";
import { makeStyles } from "@material-ui/core/styles";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import clsx from "clsx";

const useStyles = makeStyles(() => ({
  root: {
    marginBottom: "1rem",
  },
  noScroll: {
    overflowY: "hidden",
  },
}));

type Props = {
  dialogContextText?: string;
  children: ReactNode;
  isOpen: boolean;
  noScroll?: boolean;
  title?: string;
  onClose?: () => void;
};

const DialogWrapper: FC<Props> = ({
  dialogContextText,
  children,
  isOpen,
  title,
  onClose,
  noScroll,
  ...rest
}) => {
  const classes = useStyles();
  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby="form-dialog"
      {...rest}
    >
      {title ? (
        <DialogTitle id="form-dialog-title">{title}</DialogTitle>
      ) : null}
      <DialogContent
        className={clsx(classes.root, noScroll && classes.noScroll)}
      >
        <DialogContentText>{dialogContextText}</DialogContentText>
        {children}
      </DialogContent>
    </Dialog>
  );
};

export default DialogWrapper;
