import { createMuiTheme } from "@material-ui/core/styles";
import { Overrides } from "@material-ui/core/styles/overrides";

import customTypography from "./typography";
import customPalette from "./palette";
import customShadows from "./shadows";
import useButtons from "./use-buttons";
import useChips from "./use-chips";
import useTests from "./use-tests";
import links from "./links";
import useInputs from "./use-inputs";

const theme = createMuiTheme({});

const buttons = useButtons();
const chips = useChips(theme);
const tests = useTests(theme);
const inputs = useInputs(theme);

theme.overrides = {
  MuiAutocomplete: {
    option: {
      // eslint-disable-next-line quotes
      '&[data-focus="true"]': {
        backgroundColor: customPalette.secondary["10"],
      },
      // eslint-disable-next-line quotes
      '&[aria-selected="true"]': {
        backgroundColor: customPalette.secondary["20"],
      },
    },
  },
  MuiFormHelperText: {
    root: {
      // TODO instead of this override explicitely only .Mui-error CSS class
      color: `${customPalette.error} !important`,
    },
  },
} as Overrides;

const paraqeet = {
  ...theme, // assure backwards compatibility
  Avatar: {
    influencers: {
      height: 80,
      width: 80,
      [theme.breakpoints.down("sm")]: {
        height: 56,
        width: 56,
      },
    },
  },
  typography: {
    ...theme.typography,
    ...customTypography,
  },
  palette: {
    ...theme.palette,
    ...customPalette,
    primary: {
      ...customPalette.primary,
      ...theme.palette.primary,
    },
    secondary: {
      ...customPalette.secondary,
      ...theme.palette.secondary,
    },
    tertiary: {
      ...customPalette.tertiary,
      // ...theme.palette.tertiary,
    },
  },
  ...[theme.shadows],
  shadowsCustom: {
    ...customShadows,
  },
  links: {
    ...links,
  },
  buttons: {
    ...buttons,
  },
  inputs: {
    ...inputs,
  },
  chips: {
    ...chips,
  },
  // I keep this: useful for testing
  // material UI design system weird things
  tests: {
    ...tests,
  },
};
// console.log("##", paraqeet);
// eslint-disable-next-line import/prefer-default-export
export { paraqeet };
