import gql from "graphql-tag";
import { Resolvers } from "@apollo/client";
import {
  setLocale,
  shiftApolloError,
  purgeApolloErrors,
  pushApolloError,
} from "./var";

export const typeDefs = gql`
  type ApolloError {
    id: ID
    message: String!
    name: String!
    statusCode: Int
  }
  extend type Query {
    apolloErrors: [ApolloError]!
    counterInputMethod: String
    countryName: String
    dateRangeOption: String
    dateRangeInputMethod: String
  }
`;

export const resolvers: Resolvers = {
  Mutation: {
    setLocale,
    shiftApolloError,
    purgeApolloErrors,
    pushApolloError,
  },
};
