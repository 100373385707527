/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMemo } from "react";
import { useQuery, gql } from "@apollo/client";
import { TranslationsQuery } from "../../../build/types/codegen";

type KeyValue = {
  key?: string | null;
  value?: string | null;
};

export type Translations = Record<string, string | null | undefined>;

const query = gql`
  query Translations {
    translations {
      key
      value
    }
  }
`;

interface UseTranslations {
  (): [Translations, boolean];
}

export const useTranslations: UseTranslations = () => {
  const { data, loading } = useQuery<TranslationsQuery>(query);
  const cacheKey = Object.values(data?.translations || {})
    .map((item) => item?.value)
    .join(",");

  const results = useMemo(() => {
    const nextTranslations =
      data?.translations?.reduce(
        (acc: Translations, { key, value }: KeyValue) => {
          if (key) {
            return {
              ...acc,
              [key]: value,
            };
          }
          return acc;
        },
        {},
      ) || ({} as Translations);

    return nextTranslations;
  }, [cacheKey]);

  return [results, loading];
};
