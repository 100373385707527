export const sessionWrite = <T>(key: string, value: T): void =>
  sessionStorage.setItem(key, JSON.stringify(value));

export const sessionRead = <T>(
  key: string,
  fallback?: T,
): T | null => {
  const json = sessionStorage.getItem(key);
  if (json === null)
    return typeof fallback === "undefined" ? null : fallback;
  return JSON.parse(json);
};

export const sessionRemoveItem = (key: string): void =>
  sessionStorage.removeItem(key);
