import React, { FC } from "react";
import { makeStyles } from "@material-ui/core/styles";

import { Button } from "../ui";
import { useTranslations } from "../apollo/query/useTranslations";

const useStyles = makeStyles(() => ({
  button: {
    borderRadius: "10px",
    marginTop: "1rem",
  },
  img: {
    marginLeft: "0.5rem",
  },
}));

type Props = {
  // eslint-disable-next-line react/require-default-props
  disabled?: boolean;
  id: string;
  onClick(): void;
};

export const LoginButton: FC<Props> = ({ onClick, disabled, id }) => {
  const classes = useStyles();
  const [{ signIn }] = useTranslations();
  return (
    <Button
      alternative="secondary"
      size="large"
      disabled={disabled}
      color="primary"
      onClick={onClick}
      className={classes.button}
      id={id}
    >
      <span>{signIn}</span>
      <img
        alt="logo"
        src="imgs/google-logo.svg"
        width="25px"
        className={classes.img}
      />
    </Button>
  );
};
