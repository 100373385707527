/* eslint-disable react/require-default-props */
import React, { FC, ReactNode } from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

import { paraqeet } from "./theme";

type Props = {
  count?: string;
  icon: JSX.Element;
  prominent?: boolean;
  text: ReactNode;
};

const useStyles = makeStyles((theme: typeof paraqeet) => ({
  chip: {
    alignItems: "center",
    color: theme.palette.deactiveText,
    display: "flex",
    margin: "0 16px",
  },
  text: {
    padding: "0 8px",
  },
  counter: {
    fontWeight: 600,
    paddingLeft: 8,
  },
  prominent: {
    color: theme.palette.primaryText,
    fontWeight: 600,
  },
}));

export const TwitterChip: FC<Props> = ({
  count,
  icon,
  prominent,
  text,
}): JSX.Element => {
  const css = useStyles();
  return (
    <Typography
      className={`${css.chip} ${prominent && css.prominent}`}
      variant="body2"
    >
      {icon}
      <span className={css.text}>{text}</span>
      {count && <span className={css.counter}>{count}</span>}
    </Typography>
  );
};
