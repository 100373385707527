import palette from "./palette";

const fallBack =
  "San Francisco, Helvetica Neue, Roboto, Segoe UI, sans-serif";

export default {
  fontFamily: [
    `Poppins Light, ${fallBack}`,
    `Poppins Light Italic, ${fallBack}`,
    `Poppins Medium, ${fallBack}`,
    `Poppins Medium Italic, ${fallBack}`,
    `Poppins Regularz, ${fallBack}`,
    `Poppins Regular Italic, ${fallBack}`,
    `Poppins SemiBold, ${fallBack}`,
    `Poppins SemiBold Italic, ${fallBack}`,
    `Poppins Bold, ${fallBack}`,
    `Poppins Bold Italic, ${fallBack}`,
  ],
  body: {
    fontFamily: `Poppins Regular, ${fallBack}`,
    fontSize: "14px",
    fontWeight: "400",
  },
  bodyMedium: {
    fontFamily: `Poppins Medium, ${fallBack}`,
    fontSize: "14px",
    fontWeight: "500",
  },
  bodyBold: {
    fontFamily: `Poppins Bold, ${fallBack}`,
    fontSize: "14px",
    fontWeight: "700",
  },
  button: {
    fontFamily: `Poppins Regular, ${fallBack}`,
    fontSize: "16px",
    fontWeight: "400",
  },
  title: {
    fontFamily: `Poppins SemiBold, ${fallBack}`,
    fontSize: "16px",
    fontWeight: "600",
  },
  titleBigger: {
    fontFamily: `Poppins SemiBold, ${fallBack}`,
    fontSize: "20px",
    fontWeight: "600",
  },
  subTitle: {
    fontFamily: `Poppins Medium, ${fallBack}`,
    fontSize: "14px",
    fontWeight: "500",
  },
  formCaption: {
    fontFamily: `Poppins Regular, ${fallBack}`,
    fontSize: "14px",
    fontWeight: "400",
  },
  form: {
    fontFamily: `Poppins Regular, ${fallBack}`,
    fontSize: "16px",
    fontWeight: "400",
  },
  formBold: {
    fontFamily: `Poppins SemiBold, ${fallBack}`,
    fontSize: "16px",
    fontWeight: "600",
  },
  formDataPicker: {
    fontFamily: `Poppins Regular, ${fallBack}`,
    fontSize: "20px",
    fontWeight: "400",
  },
  formDatePickerBold: {
    fontFamily: `Poppins SemiBold, ${fallBack}`,
    fontSize: "20px",
    fontWeight: "600",
  },
  formSubtitle: {
    fontFamily: `Poppins Regular, ${fallBack}`,
    fontSize: "12px",
    fontWeight: "400",
  },
  formWarning: {
    color: `${palette.tertiary.main}`,
    transform: "translateY(-16px)",
  },
};
