import React, { FC } from "react";
import { makeStyles } from "@material-ui/core/styles";
import useLockBodyScroll from "react-use/lib/useLockBodyScroll";

const useStyles = makeStyles({
  box: {
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    position: "fixed",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "stretch",
    alignContent: "stretch",
    "& > *": {
      flex: 1,
    },
  },
});

export const FixedMain: FC = ({ children }) => {
  const css = useStyles();
  useLockBodyScroll();
  return <main className={css.box}>{children}</main>;
};
