const composeSearch =
  (path: string) =>
  (search?: string): string =>
    `${path}${search || ""}`;

export const getAdminPath = (): string => "/admin";
export const getSignInPagePath = (): string => "/";
export const getSearchPagePath = (): string => "/search";
export const getStyleGuidePath = (): string => "/style-guide";
export const getInsightsPagePath = composeSearch("/insights");
export const getPerspectivePagePath = composeSearch("/perspective");
export const getInfluencersPagePath = composeSearch("/influencers");
export const getSearchFormPagePath = (): string => "/search";
export const getLabPagePath = (): string => "/lab"; // just for experiments
export const getRequestAccessPath = (): string => "/request-access";
export const getMySearchesPath = (): string => "/my-searches";

// server managed routing actions
export const getLoginAction = (): string => "/login";
