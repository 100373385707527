/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable react/destructuring-assignment */
import React, { Component, ReactNode, ErrorInfo } from "react";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";

import { ErrorPage } from "../error-page";
import { useTranslations } from "../../apollo/query/useTranslations";
import {
  getDecodedToken,
  USER_TOKEN_KEY,
} from "../../utils/local-storage";
import { TOKEN_EXPIRED } from "../../constants";

const ignoreErrors = new Set([TOKEN_EXPIRED]);

Bugsnag.start({
  apiKey: process.env.BUGSNAG_API_KEY!,
  appVersion: process.env.VERSION!,
  async onError(event) {
    const user = getDecodedToken(USER_TOKEN_KEY);
    if (user) {
      event.setUser(user.email, user.email, user.name);
    }
    const shouldIgnore = ignoreErrors.has(
      event.originalError.message,
    );
    if (shouldIgnore) {
      return false;
    }
    return undefined;
  },
  plugins: [new BugsnagPluginReact(React)],
});

type Props = Record<string, unknown>;
type State = { hasError: boolean };

const Message = (): JSX.Element => {
  const [{ appCrushed, comeBackLater }] = useTranslations();
  return (
    <>
      <p>{appCrushed}</p>
      <p>{comeBackLater}</p>
    </>
  );
};

export class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(): State {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    Bugsnag.notify(error, (event) => {
      event.addMetadata("errorInfo", errorInfo);
    });
  }

  render(): ReactNode {
    if (this.state.hasError)
      return <ErrorPage message={<Message />} title="Error" />;

    return this.props.children;
  }
}
