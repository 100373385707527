// eslint-disable-next-line import/no-extraneous-dependencies
import { styled } from "@material-ui/styles";

const Form = styled("form")({
  width: "100%",
  maxWidth: "640px",
  paddingLeft: "1rem",
  paddingRight: "1rem",
  /*   paddingLeft: "1.5rem",
    paddingRight: "1.5rem",
    paddingBottom: "1rem",
    [theme.breakpoints.up("sm")]: {
      paddingLeft: "3rem",
      paddingRight: "3rem",
      paddingBottom: "2rem",
    }, */
});

export default Form;
