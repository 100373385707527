import customPalette from "./palette";
import customTypography from "./typography";

const baseButton = {
  border: 0,
  boxSizing: "content-box",
  borderRadius: "2px",
  padding: "15px 25.55px",
  lineHeight: "24px",
  display: "flex",
  alignItems: "center",
  textAlign: "center",
  justifyContent: "center",
  letterSpacing: "-0.02em",
};

const useButtons = (): Record<string, unknown> => ({
  white: {
    color: "white",
  },
  primary: {
    ...customTypography.button,
    ...baseButton,
    color: "white",
    border: `2px solid ${customPalette.primary["80"]}`,
    backgroundColor: customPalette.primary["80"],
    "&:hover": {
      border: `2px solid ${customPalette.primary["80"]}`,
      backgroundColor: customPalette.primary["80"],
    },
    "&:active": {
      border: `2px solid ${customPalette.primary["100"]}`,
      backgroundColor: customPalette.primary["100"],
    },
    "&:disabled": {
      border: `2px solid ${customPalette.primary["10"]}`,
      backgroundColor: customPalette.primary["10"],
      color: customPalette.primary["20"],
    },
  },
  secondary: {
    ...customTypography.button,
    ...baseButton,
    color: customPalette.primary["100"],
    border: `2px solid ${customPalette.primary["100"]}`,
    backgroundColor: "white",
    "&:hover": {
      backgroundColor: customPalette.primary["10"],
    },
    "&:active": {
      backgroundColor: customPalette.primary["20"],
    },
    "&:disabled": {
      border: `2px solid ${customPalette.primary["10"]}`,
      backgroundColor: customPalette.primary["10"],
      color: customPalette.primary["20"],
    },
  },
  tertiary: {
    ...customTypography.button,
    ...baseButton,
    color: customPalette.primary["100"],
    border: "2px solid white",
    backgroundColor: "white",
    "&:hover": {
      border: `2px solid ${customPalette.primary["10"]}`,
      backgroundColor: customPalette.primary["10"],
    },
    "&:active": {
      border: `2px solid ${customPalette.primary["20"]}`,
      backgroundColor: customPalette.primary["20"],
    },
    "&:disabled": {
      border: `2px solid ${customPalette.primary["10"]}`,
      backgroundColor: "white",
      color: customPalette.primary["20"],
    },
  },
});

export default useButtons;
