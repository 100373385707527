import React from "react";
import { useLocation, useHistory } from "react-router-dom";

import { getLoginAction, getSearchFormPagePath } from "../paths";
import { toObject } from "../utils/query-string-helpers";
import {
  USER_TOKEN_KEY,
  LAST_FORBIDDEN_URL_KEY,
  saveInLocalStorage,
  getValidToken,
  removeLocalStorageKey,
  getFromLocalStorage,
} from "../utils/local-storage";
import { LogoVertical } from "../svg";
import Layout from "../components/layout";
import { FullViewPortLoading } from "../components/loading/fullviewport-loading";
import { LoginButton } from "../components/login-button";

const SignInPage = (): JSX.Element => {
  const history = useHistory();
  const location = useLocation();
  const params = toObject(location.search);
  const token = getValidToken(USER_TOKEN_KEY);
  const handleOnClick = (): void => {
    window.location.href = getLoginAction();
  };

  React.useEffect(() => {
    if (params.token) {
      const lastForbiddenUrl = getFromLocalStorage(
        LAST_FORBIDDEN_URL_KEY,
      );
      const redirectPath =
        lastForbiddenUrl || getSearchFormPagePath();
      removeLocalStorageKey(LAST_FORBIDDEN_URL_KEY);
      saveInLocalStorage(USER_TOKEN_KEY, params.token);
      history.push(redirectPath);
    }
    if (token) {
      window.location.href = getSearchFormPagePath();
    }
  }, []);
  if (params.token) {
    return <FullViewPortLoading />;
  }
  return (
    <Layout centered>
      <LogoVertical />
      <LoginButton onClick={handleOnClick} id="login-button" />
    </Layout>
  );
};

export default SignInPage;
