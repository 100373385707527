/* eslint-disable react/require-default-props */
import React, { FC, ReactNode } from "react";
import CoreFormHelperText from "@material-ui/core/FormHelperText";
import { styled } from "@material-ui/core/styles";
import {
  spacing,
  compose,
  flexbox,
  style,
} from "@material-ui/system";

const typography = style({
  prop: "alternative",
  cssProperty: false,
  themeKey: "typography",
});

const Variant = styled(CoreFormHelperText)(
  compose(spacing, flexbox, typography),
);

type Props = {
  children: ReactNode;
  id?: string;
  error?: boolean;
};

const FormHelperText: FC<Props> = ({ children, ...props }) => (
  <Variant {...props} alternative="formSubtitle">
    {children}
  </Variant>
);

export default FormHelperText;
